import React from "react";
import {CenteredContainer} from "../../style";
import educationPortalBadge from "../../assets/images/education-portal-badge.svg";

const HeadingPart = () => {
  return (
    <CenteredContainer className="knowledge section-spacing">
      <div className="flex-container vertical-middle">
        <div className="img-container">
          <img src={educationPortalBadge} className="logo" alt="logo"/>
        </div>
        <div className="heading-container flexbox">
          <h2 className="heading-text">
            <span>Knowledge beyond boundaries</span>
            and accessible to all
          </h2>
        </div>
      </div>
    </CenteredContainer>
  );
};

export default HeadingPart;