import React, {useState} from "react";
import {EducationPortalContainer} from "../../style";
import searchImg from "../../assets/images/search.svg";

import {professionList, interestList, resourceList, mediumList} from "../common.config";

const EducationPortal = () => {
  const [medium, setMedium] = useState([]);
  const [interest, setInterest] = useState([]);
  const [resource, setResource] = useState([]);
  const [profession, setProfession] = useState([]);
  const [showMenu, setShowMenu] = useState([false, false, false, false]);

  const handleCheckbox = (currentValue, existingValue) => {
    const clonedExisting = [...existingValue];
    if(clonedExisting.includes(currentValue)){
      const index = clonedExisting.indexOf(currentValue);
      clonedExisting.splice(index, 1);
      return clonedExisting;
    }

    clonedExisting.push(currentValue);
    return clonedExisting;
  };

  const handleClick = (index, option) => {
    const clonedMenu = [...option];
    clonedMenu[index] = !clonedMenu[index];
    return clonedMenu;
  }
  
  return (
    <EducationPortalContainer>
      <div className="grid-container">
        <div className="searchbox">
          <div className="flex-container vertical-middle">
            <div className="input flexbox">
              <input type="text" className="input-field" placeholder="Search..."/>
            </div>
            <div className="search">
              <img src={searchImg} alt="search knowledge"/> 
            </div>
          </div>
        </div>
        <div
          className={`profession grid${showMenu[0] ? " open" : ""}`}
          onClick={() => setShowMenu(handleClick(0, showMenu))}
          onKeyDown={() => setShowMenu(handleClick(0, showMenu))}
          role="button"
          tabIndex="0"
        >
          <div className="heading">
            Your Profession
            <span className="arrow"/>
          </div>
          <ul className="list">
            {
              professionList.map(el => {
                const {text, value} = el;
                return (
                  <li key={value}>
                    <label className="custom-input" data-type="checkbox">
                      {text}
                      <input 
                        type="checkbox" 
                        name="profession" 
                        value={value} 
                        onChange={() => {
                          setProfession(handleCheckbox(value, profession));
                        }} 
                        checked={profession.includes(value)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                );
              })
            }
          </ul>
        </div>
        <div
          className={`interests grid${showMenu[1] ? " open" : ""}`}
          onClick={() => setShowMenu(handleClick(1, showMenu))}
          onKeyDown={() => setShowMenu(handleClick(1, showMenu))}
          role="button"
          tabIndex="0"
        >
          <div className="heading">
            Interests
            <span className="arrow"/>
          </div>
          <ul className="list">
            {
              interestList.map(el => {
                const {text, value} = el;
                return (
                  <li key={value}>
                    <label className="custom-input" data-type="checkbox">
                      {text}
                      <input 
                        type="checkbox" 
                        name="interests" 
                        value={value} 
                        onChange={() => {
                          setInterest(handleCheckbox(value, interest));
                        }} 
                        checked={interest.includes(value)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                );
              })
            }
          </ul>
        </div>
        <div
          className={`resource grid${showMenu[2] ? " open" : ""}`}
          onClick={() => setShowMenu(handleClick(2, showMenu))}
          onKeyDown={() => setShowMenu(handleClick(2, showMenu))}
          role="button"
          tabIndex="0"
        >
          <div className="heading">
            Resource
            <span className="arrow"/>
          </div>
          <ul className="list">
            {
              resourceList.map(el => {
                const {text, value} = el;
                return (
                  <li key={value} data-checked={resource.includes(value)}>
                    <label className="custom-input" data-type="checkbox">
                      {text}
                      <input 
                        type="checkbox" 
                        name="resource" 
                        value={value} 
                        onChange={() => {
                          setResource(handleCheckbox(value, resource));
                        }} 
                        checked={resource.includes(value)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                );
              })
            }
          </ul>
        </div>
        <div
          className={`medium grid${showMenu[3] ? " open" : ""}`}
          onClick={() => setShowMenu(handleClick(3, showMenu))}
          onKeyDown={() => setShowMenu(handleClick(3, showMenu))}
          role="button"
          tabIndex="0"
        >
          <div className="heading">
            Medium
            <span className="arrow"/>
          </div>
          <ul className="list">
            {
              mediumList.map(el => {
                const {text, value} = el;
                return (
                  <li key={value} data-checked={medium.includes(value)}>
                    <label className="custom-input" data-type="checkbox">
                      {text}
                      <input 
                        type="checkbox" 
                        name="medium" 
                        value={value} 
                        onChange={() => {
                          setMedium(handleCheckbox(value, medium));
                        }} 
                        checked={medium.includes(value)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                );
              })
            }
          </ul>
        </div>
        <div className="result-container">
          {
            [...new Array(15).keys()].map(el => {
              return (
                <div className="result" key={el}>
                  <div className="flex-container">
                    <div className="flexbox">
                      <h2 className="heading">Topic Title - {el + 1}</h2>
                      <p className="sub-heading">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                      </p>
                    </div>
                    <div className="btn-container">
                      <button type="button" className="btn deep-green text-uppercase">Enroll</button>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </EducationPortalContainer>
  )
};

export default EducationPortal;
