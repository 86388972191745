import React from "react";
import {GlobalStyle, MainContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";

import HeadingPart from "../components/education/headingPart";
import EducationPortal from "../components/education/educationPortal";

const Education = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <HeadingPart/>
      <EducationPortal/>
      <FooterBar/>
    </MainContainer>
  );
};

export default Education;